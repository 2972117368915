import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoaderService } from 'src/app/core/services/loader.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styles: []
})
export class LoaderComponent implements OnInit, OnDestroy {

  loader : boolean = false;
  subscription : Subscription;

  constructor(private loaderService: LoaderService) { }

  ngOnInit() {
    this.subscription = this.loaderService.loadCountAction$.subscribe(
      val => this.loader = val
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
