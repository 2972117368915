import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Novel, NovelImage } from 'src/app/shared/models/batman-common-models';

@Component({
    selector: 'app-alt-image',
    templateUrl: './alt-image.component.html',
    styles: [`
            .bat-thumb { height: 120px; padding-left: 10px; padding-right: 10px; border: 0 ;}
            .selected-image { background: rgba(255, 255, 255, 1); overflow: hidden; z-index: 2;}
            .bat-opacity { opacity: 0.6; }
        `]
})
export class AltImageComponent implements OnChanges {

    @Input() images: Array<NovelImage>;
    @Input() novel: Novel;
    filteredImages: Array<NovelImage> = [];
    types: Array<string> = ["alternate", "primary"];
    selectedImage: NovelImage;

    constructor() { }


    ngOnChanges(changes : SimpleChanges) : void {
        if(changes['images']){
            let tmp : Array<NovelImage> = changes['images'].currentValue;
            this.filteredImages = tmp.filter((x) => this.types.indexOf(x.imageType) !== -1);
            this.setImages();
        }
    }

    setImages() : void {
        this.selectedImage = this.selectImageByType('primary');
        if(!this.selectedImage) {
            this.selectedImage = this.selectImageByType('alternate');
        }
    }

    selectImageByType(type: string) : NovelImage {
        return this.filteredImages.find(x => x.imageType === type);
    }

    cleanText(text): string {
        if (text) {
            return text.replace(/\"/g, "");
        }
    };
}
