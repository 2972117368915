<div class="modal-header">
    <h4 class="modal-title">Ratings for {{novelTitle}}</h4>
    <button type="button" class="close cursor ml-auto" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">    
    <table class="table table-condensed table-striped">
        <thead>
            <tr>
                <th>User</th>
                <th>Rating</th>
            </tr>
        </thead>
        <tr *ngFor="let rating of ratings">
            <td>{{rating.user.userName}}</td>
            <td>
                <app-rating [rating]="rating.novelRating" [isReadOnly]="true"></app-rating>
            </td>
        </tr>
    </table>
</div>