import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Paging } from '../../models/paging';


@Component({
    selector: 'app-paging',
    templateUrl: './paging.component.html',
    styles: []
})
export class PagingComponent implements OnInit {

    @Input() paging: Paging
    @Output() onPageChanged: EventEmitter<number> = new EventEmitter();

    constructor() { }

    ngOnInit() {
    }

    pageChanged($event) : void {
        this.onPageChanged.emit($event);
    }

}
