import {User} from './user-models';

export class Novel {
    constructor() {
        this.id = 0;
        this.bNovelType = new NovelType();
        this.publishedBy = new Publisher();
        this.compilation = false;
        this.active = true;
     }

    id: number;
    title: string;
    coverText: string;
    published: string;
    compilation: boolean;
    active: boolean;
    bNovelType: NovelType;
    publishedBy: Publisher;
    chapters: Chapter[];       
    authors: Author[];
    orderBy: number;
    images: NovelImage[];
}

export class NovelImage {
    constructor(_imagename, _imagetype) {
        this.imageName = _imagename;
        this.imageType = _imagetype;
    }

    id: number;
    imageName: string;
    imageType: string;
}

export class NovelImageType {
    id: number;
    imageType: string;
}

export class RelatedNovel {
    id: number;
    title: string;
    bookImage_100: string;
}

export class NovelType {
    constructor(){}

    id: number; 
    novelType: string;
    sortOrder?: number;   
    description: string;
    image: string
}

export class Publisher {

    constructor() {}
    
    id: number; 
    name: string;
}

export class Chapter {

    constructor() {
    }

    id: number; 
    novelId: number; 
    chapterNumber?: number; 
    chapterTitle: string;
    chapterAuthors?: Author[];
}

export class Author {

    constructor(){}

    id: number; 
    authorName: string;
}

export interface RatingSummary {
    rating: number;
    numRatings: number;
}

export class Comment {

    constructor() {}

    createComment(_comment: string, _parentNovel: Novel) {
        this.novelComment = _comment;
        this.parentNovel = _parentNovel;
    }

    createdDate: Date;
    novelComment: string;
    email: string;
    userName: string;
    parentNovel: Novel;
    active: boolean
}

export class Rating {
    parentNovel: Novel;
    novelRating: number;
    user: User;    
}

export interface BatToken {
    token: string;
    refreshToken: string;
}


export class ModuleNavigation {
    label: string;
    route: string;
    claims?: Array<string>;
    showIfAuthenticated?: boolean;
    external?: boolean;
}

export class NavigationModel{

    constructor(_display, _route, _external) {
        this.display = _display;
        this.route = _route;
        this.external = _external;
    }

    display: string;
    route: string;
    external: boolean;
}

export class QuickNav {
    id: number;
    title: string;
    orderBy: number; 
    novelType: number;
}
