import { createSelector} from '@ngrx/store';
import { NovelType, QuickNav } from 'src/app/shared/models/batman-common-models';
import {AppState} from '../state';
import * as _ from 'lodash-es';


export const selectNovelTypes = createSelector(
    (state: AppState) => state.novels.novelTypes, 
    (novelTypes: NovelType[]) => novelTypes
);

export const selectQuickNav = createSelector(
    (state:AppState) => state.novels.quickNav, 
    (quickNav: QuickNav[]) => quickNav
);

