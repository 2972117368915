<div *ngIf="chapters?.length > 0">
    <table class="table table-striped table-sm admin-table">
        <thead>
            <tr>
                <th>Chapter</th>
                <th>Author(s)</th>
                <th *ngIf="editable"></th>
                <th *ngIf="editable"></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let chapter of chapters">
                <td>{{chapter.chapterTitle}}</td>
                <td><authors-list [authors]="chapter.chapterAuthors"></authors-list></td>
                <td *ngIf="editable"><i class="fa fa-pencil-square-o" aria-hidden="true" (click)="edit(chapter)"></i></td>
                <td *ngIf="editable"><i class="fa fa-trash-o" aria-hidden="true" (click)="delete(chapter.id)"></i></td>
            </tr>
        </tbody>
    </table>
</div>
<div *ngIf="!chapters || chapters.length == 0">
    There are currently no chapters associated with this title
</div>