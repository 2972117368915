import { Injectable } from '@angular/core';
import {props, Store} from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { BatmanCommonService } from 'src/app/core/services/batman-common.service';
import {selectNovelTypes, selectQuickNav} from '../selectors/novel.selector';
import * as constants from '../constants'

//https://stackabuse.com/beginners-guide-to-ngrx-and-angular/

@Injectable()
export class NovelEffects {

    constructor(private actions$: Actions, private store: Store, private batmanCommonService: BatmanCommonService) { }

    loadNovelTypes$ = createEffect(() => this.actions$.pipe(
        ofType(constants.CommonNovel_LoadNovelTypes),
        withLatestFrom(this.store.select(selectNovelTypes)), 
        // tap(([action, novels]) => {
        //     console.log(action, novels);
        // }),
        switchMap(([action, novels]) => {
            if(novels.length  > 0) {
                return of({ type: constants.CommonNovel_NovelTypesLoaded, novelTypes: novels});
            }
            return this.batmanCommonService.getNovelTypes().pipe(
                    map(res => ({ type: constants.CommonNovel_NovelTypes, novelTypes: res }),                
                        catchError(err => of({ type: '[Common Novel] Novel Error', payload: err })))
            )
        })
    ));

    loadQuickNav$ = createEffect(() => this.actions$.pipe(
        ofType(constants.CommonNovel_LoadQuickNav), 
        withLatestFrom(this.store.select(selectQuickNav)), 
        switchMap(([action, quickNav]) => {
            if(quickNav.length > 0) {
                return of({type: constants.CommonNovel_QuickNavsLoaded, quickNav: quickNav});
            }
            return this.batmanCommonService.getQuickNav().pipe(
                map(res => ({type: constants.CommonNovel_QuickNav, quickNav: res}), 
                catchError(err => of({ type: '[Common Novel] Novel Error', payload: err })))
            )
        })
    ));

}