import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http'
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import { MessagingLibraryService } from 'angular-messaging-library';


export abstract class HttpExtendService {
    constructor(private httpClient: HttpClient, private router: Router, private messagingService: MessagingLibraryService) { 
    }

    public get<T>(url: string, headers?: HttpHeaders): Observable<T> {
        return this.httpClient.get<T>(this.appendUrl(url), { headers: headers }).pipe(catchError((err) => { return this.errorHandler(err) }));
    }

    public post<T>(url: string, body: any, headers?: HttpHeaders): Observable<T> {
        return this.httpClient.post<T>(this.appendUrl(url), body, { headers: this.appendDefaultHeaders(headers) }).pipe(catchError((err) => { return this.errorHandler(err) }));
    }

    public put<T>(url: string, body: any, headers?: HttpHeaders) : Observable<T> {
        return this.httpClient.put<T>(this.appendUrl(url), body, { headers: this.appendDefaultHeaders(headers) }).pipe(catchError((err) => { return this.errorHandler(err) }));
    }

    public delete<T>(url: string, headers?: HttpHeaders): Observable<T> {
        return this.httpClient.delete(this.appendUrl(url), {headers: headers}).pipe(catchError((err) => { return this.errorHandler(err) }));
    }

    public appendUrl(url: string): string {
        if (url[0] !== '/') {
            url += '/';
        }
               
        return environment.url + url;
    }

    private appendDefaultHeaders(headers: HttpHeaders): HttpHeaders{
        return this.appendContentType(headers);
    }

    private appendContentType(headers : HttpHeaders) : HttpHeaders{
        if(headers && headers.has("Content-Type")){
             return headers;
        }
        else if(headers) {   
            headers.append("Content-Type", "application/json");
            return headers;     
        }
        else {
            return new HttpHeaders({"Content-Type" : "application/json"});
        }
    }

    private errorHandler(error: HttpErrorResponse): Observable<any> {
        if (error.status && error.status === 401) {
            this.router.navigate(['/account/login']);
            return throwError(error);
        }
        console.log(error);
        let dftMsg = 'An error occurred. Please try again later';
        let errMsg: any;
        if(error instanceof Error){
            errMsg = error.message;
        }
        else {
            errMsg = error.error || dftMsg;
        }
        this.messagingService.addErrorMessage(errMsg);
        return throwError(errMsg);
    }
}
