import {createAction, props} from '@ngrx/store';
import { NovelType, QuickNav } from 'src/app/shared/models/batman-common-models';
import * as constants from '../constants'


export const setNovelTypes = createAction(
    constants.CommonNovel_NovelTypes, 
    props<{novelTypes: NovelType[]}>()
);

export const loadNovelTypes = createAction(
    constants.CommonNovel_LoadNovelTypes
);

export const novelTypesLoaded = createAction(
    constants.CommonNovel_NovelTypesLoaded, 
    props<{novelTypes: NovelType[]}>()
);


export const setQuickNav = createAction(
    constants.CommonNovel_QuickNav, 
    props<{quickNav: QuickNav[]}>()
);

export const loadQuckNav = createAction(
    constants.CommonNovel_LoadQuickNav
);

export const quickNavsLoaded = createAction(
    constants.CommonNovel_QuickNavsLoaded, 
    props<{quickNav: QuickNav[]}>()
);


export const handleError = createAction(
    '[Common Novel] Novel Error', 
    props<{payload: any}>()
);