import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Router } from '@angular/router';
import { Contact } from '../models/batman-models';
import { HttpExtendService } from '../../core/services/http-extend.service';
import { Observable } from 'rxjs';
import {Comment, Novel, RatingSummary, Rating, RelatedNovel } from '../../shared/models/batman-common-models';
import { MessagingLibraryService } from 'angular-messaging-library';
import { ApiPaging, ApiPagingResult } from 'src/app/shared/models/paging';

@Injectable({
    providedIn: 'root'
})
export class BatmanService extends HttpExtendService {

    constructor(httpClient: HttpClient, router: Router, messagingService: MessagingLibraryService) { 
        super(httpClient, router, messagingService);
    }

    sendContact(contact: Contact): Observable<any> {
        return this.post('/batman/contact', contact);
    }

    postNovelComment(comment: Comment): Observable<any> {
        return this.post('/batman/comment/', { ParentNovel: { Id: comment.parentNovel.id }, NovelComment: comment.novelComment });
    }

    getNovelById(novelId: number): Observable<Novel> {
        return this.get('/batman/novel/' + novelId);
    }

    getAverageRating(novelId: number) : Observable<RatingSummary> {
        return this.get('/batman/averagerating/' + novelId);
    }

    getNovelComments(novelId: number): Observable<Comment[]> {
        return this.get('/batman/comments/' + novelId);
    }

    getUserRating(novelId: number): Observable<Rating> {
        return this.get('/batman/rating/' + novelId);
    }

    getRatings(novelId: number): Observable<Rating[]> {
        return this.get('/batman/ratings/' + novelId);
    }

    postNovelRating(rating: Rating): Observable<any> {
        return this.post('/batman/rating/', rating);
    }  

    getRelatedNovels(novelId: number) : Observable<Array<RelatedNovel>> {
        return this.get('/batman/novel/' + novelId + '/related');
    }

    getNovelsWithPaging(novelTypeId: number, paging: ApiPaging) : Observable<ApiPagingResult> {
        return this.post(`/batman/novels/${novelTypeId}/type`, paging);
    }
}
