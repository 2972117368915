import { Component, OnInit } from '@angular/core';
import { Novel, QuickNav } from 'src/app/shared/models/batman-common-models';
import { Router } from '@angular/router';
import {Store} from '@ngrx/store';
import {selectQuickNav} from '../../store/selectors/novel.selector';
import {MessagingLibraryService} from 'angular-messaging-library';

@Component({
    selector: 'quick-nav',
    templateUrl: './quick-nav.component.html',
    styleUrls: ['./quick-nav.component.css']
})
export class QuickNavComponent implements OnInit {

    novels : QuickNav[]; 
    formattedSelectionList: Novel[] = [];
    selectedNovel: Novel;


    constructor(private store:Store, private router: Router, private messageingLibraryService : MessagingLibraryService) { }

    ngOnInit() {
        this.store.select(selectQuickNav).subscribe(
            res => {
                if(res) {
                    this.novels  = res;
                    this.formatList();
                    this.selectedNovel = this.formattedSelectionList[0];
                }
            },
            err => this.messageingLibraryService.addErrorMessage(err)
        )       
    }

    onChange($event: Novel) : void {
        this.router.navigate(['/novel', $event.id]);
    }

    formatList() : void {
        if (this.novels.length > 0) {
            this.formattedSelectionList.push(this.createDummyNovel('BatmanNovels.com Quick Nav'));
            this.formattedSelectionList.push(this.createDummyNovel(''));
            this.formattedSelectionList.push.apply(this.formattedSelectionList, this.getNovelsByType(3));
            this.formattedSelectionList.push(this.createDummyNovel(''));
            this.formattedSelectionList.push(this.createDummyNovel('Novelizations'));
            this.formattedSelectionList.push(this.createDummyNovel('-----------------'));
            this.formattedSelectionList.push.apply(this.formattedSelectionList, this.getNovelsByType(1));
            this.formattedSelectionList.push(this.createDummyNovel(''));
            this.formattedSelectionList.push(this.createDummyNovel('Batman/DC Universe'));
            this.formattedSelectionList.push(this.createDummyNovel('-----------------'));
            this.formattedSelectionList.push.apply(this.formattedSelectionList, this.getNovelsByType(2));
            this.formattedSelectionList.push(this.createDummyNovel(''));
            this.formattedSelectionList.push(this.createDummyNovel('DC Universe'));
            this.formattedSelectionList.push(this.createDummyNovel('-----------------'));
            this.formattedSelectionList.push.apply(this.formattedSelectionList, this.getNovelsByType(4));
        }
    }

    getNovelsByType(typeId:number): QuickNav[] {
        let filteredNovels: QuickNav[] = this.novels.filter((e, i) => {
            return e.novelType === typeId;
        });

        return filteredNovels;
    }

    createDummyNovel(text: string) : Novel {
        let dummyNovel: Novel = <Novel>{
            id: 0, title: text 
        }
        return dummyNovel;
    }

}
