import { Component, OnChanges, Input, SimpleChanges } from '@angular/core';
import { RelatedNovel } from 'src/app/shared/models/batman-common-models';
import { BatmanService } from '../services/batman-service.service';

@Component({
  selector: 'related-novels',
  templateUrl: './related-novels.component.html',
  styles: []
})
export class RelatedNovelsComponent implements OnChanges {

    @Input() novelId: number;
    novels: Array<RelatedNovel>;

    constructor(private batmanService: BatmanService)
    {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes && changes['novelId'])
        {            
            this.getNovels(+changes['novelId'].currentValue);
        }
    }

    getNovels(id: number) : void {
        if(id > 0)
        {
            this.batmanService.getRelatedNovels(id).subscribe(
                res => this.novels = res
            )
        }
    }

}
