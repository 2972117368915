import { Component, Input } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { Rating } from 'src/app/shared/models/batman-common-models';

@Component({
  selector: 'rated-novels',
  templateUrl: './rated.component.html',
  styles: []
})
export class RatedComponent {

    @Input() novelTitle: string;
    @Input() ratings: Rating[];
    
    constructor(public activeModal: NgbActiveModal) {}

}
