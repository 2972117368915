import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NovelsComponent } from './novels/novels.component';
import { NovelComponent } from './novel/novel.component';
import { FaqComponent } from './faq/faq.component';
import { ContactComponent } from './contact/contact.component';
import { NotFoundComponent } from '../not-found.component';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
    { path: 'novels', component: NovelsComponent },
    { path: 'novels/:id', component: NovelsComponent },
    { path: 'novel/:id', component: NovelComponent, data: {QuickNav: true} },
    {path: 'faq', component: FaqComponent, data: { Title: 'Frequently Asked Questions', QuickNav: true}}, 
    {path: 'contact', component: ContactComponent, data: { Title: 'Questions/Comments', QuickNav: true}}, 
    { path: '', component: HomeComponent, pathMatch: 'full', data: {QuickNav: true} },
    //{ path: '**', component: NotFoundComponent,  data: { Title: 'Page Not Found'} }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class BatmanRoutingModule { }
