import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { User } from './shared/models/user-models';
import { TokenService } from './core/services/token-service.service';
import { AuthenticatedService } from './core/services/authenticated.service';
import * as _ from 'lodash-es';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { MessagingLibraryService } from 'angular-messaging-library';
import { BatmanCommonService } from './core/services/batman-common.service';
import { NavigationModel, ModuleNavigation } from './shared/models/batman-common-models';
import { Store } from '@ngrx/store';
import { Navigation_LoadNavigation, CommonNovel_LoadNovelTypes, CommonNovel_LoadQuickNav } from './store/constants';
import { selectNavigationProps } from './store/selectors/navigation.selector';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
    title: string = 'BatmanNovels.com';
    year: number = new Date().getFullYear();
    skipModules = ['', 'novels', 'faq', 'contact', 'novel'];
    user: User = null;
    subnavs = [];
    currentsubnavs = [];
    module: string;
    pageTitle: string;
    showQuicknav: boolean;
    @ViewChild('batmanNav') batmanNav: ElementRef;

    constructor(private tokenService: TokenService, private authenticatedService: AuthenticatedService, private route: ActivatedRoute,
        private router: Router, private messagingService: MessagingLibraryService, private commonService: BatmanCommonService, private store: Store) {
        this.authenticatedService.authenticated$.subscribe(
            () => this.setStuff()
        )


        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                let activated = this.route.firstChild;
                let module: string = activated.snapshot.url[0] ? activated.snapshot.url[0].path : '';
                let _data: Observable<any>;
                this.getNavigation(module);
                messagingService.clearMessages();
                while (activated.firstChild) {
                    activated = activated.firstChild;
                }
                _data = activated.data;
                _data.subscribe(d => {
                    this.pageTitle = d['Title'] || '';
                    this.showQuicknav = d['QuickNav'] || false;
                });
            }
        });
    }

    ngOnInit(): void {
        this.store.dispatch({ type: CommonNovel_LoadQuickNav });
        this.store.dispatch({ type: CommonNovel_LoadNovelTypes });
        this.setStuff();
    }

    setStuff() {
        this.setMenu();
        this.buildSubnavs();
    }

    setMenu() {
        if (this.tokenService.isAuthenticated()) {
            this.user = this.tokenService.getUser();
        }
        else {
            this.user = null;
        }
    }

    hasClaim(claim: string): boolean {
        return this.tokenService.hasClaim(claim);
    }

    logOff(): void {
        this.tokenService.destroy();
    }

    navBarTogglerIsVisible() {
        return this.batmanNav.nativeElement.offsetParent !== null;
    }

    collapseNav() {
        if (this.navBarTogglerIsVisible()) {
            this.batmanNav.nativeElement.click();
        }
    }

    getNavigation(module: string): void {
        if (this.skipModules.indexOf(module.toLowerCase()) === -1) {
            this.store.dispatch({ type: Navigation_LoadNavigation, module });
            this.store.select(selectNavigationProps, { module: module }).subscribe(
                res => {
                    if (res.length > 0)
                        this.buildSubnavs(res[0].payload);
                    else
                        this.buildSubnavs([]);
                },
                err => this.messagingService.addErrorMessage(err)
            )
        }
        else {
            this.buildSubnavs(null);
        }
    }

    buildSubnavs(navs?: Array<ModuleNavigation>) {
        this.currentsubnavs = [];
        if (navs && navs.length > 0) {
            navs.forEach((x: ModuleNavigation) => {
                //add claims check
                if (x.showIfAuthenticated != null) {
                    if (x.showIfAuthenticated && this.tokenService.isAuthenticated()) {
                        this.currentsubnavs.push(new NavigationModel(x.label, x.route, x.external));
                    }
                    else if (!x.showIfAuthenticated && !this.tokenService.isAuthenticated()) {
                        this.currentsubnavs.push(new NavigationModel(x.label, x.route, x.external));
                    }
                }
                if (x.claims != null && this.loopClaims(x.claims)) {
                    this.currentsubnavs.push(new NavigationModel(x.label, x.route, x.external));
                }
            });
        }
    }

    loopClaims(claims: Array<string>): boolean {
        for (let idx: number = 0; idx < claims.length; idx++) {
            if (this.hasClaim(claims[idx]))
                return true;
        }

        return false;
    }

}
