import { Pipe, PipeTransform } from '@angular/core';
import { NovelImage } from '../models/batman-common-models';

@Pipe({
  name: 'image'
})
export class ImagePipe implements PipeTransform {

  transform(value: Array<NovelImage>, type: string): string {
    if(!value || value.length === 0) return '';

    let foundImage = value.find(x => x.imageType === type);
    if(foundImage) return foundImage.imageName;

    return '';
  }

}
