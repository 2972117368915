import { BrowserModule } from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {NgbPaginationModule, NgbModalModule, NgbRatingModule,  NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import {SortablejsModule} from 'ngx-sortablejs';

import { AppComponent } from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {CoreModule} from './core/core.module';
import { BatmanModule } from './batman/batman.module';
import {MessagingLibraryModule} from 'angular-messaging-library';
import {LoaderComponent} from'./loader/loader.component';
import { StoreModule } from '@ngrx/store';
import {novelsReducer} from './store/reducers/novel.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { NovelEffects } from './store/effects/novel.effects';
import { NavigationEffects } from './store/effects/navigation.effects';
import {navigationReducer} from './store/reducers/navigation.reducer';
import { NotFoundComponent } from './not-found.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {QuickNavComponent} from './batman/quicknav/quick-nav.component';
import {SharedModule} from './shared/shared.module'

@NgModule({
  declarations: [AppComponent, 
    LoaderComponent, NotFoundComponent, QuickNavComponent],
  imports: [
    BrowserModule, NgbPaginationModule, 
    NgbModalModule, 
    NgbRatingModule,  
    NgbAlertModule, 
    CoreModule, 
    BatmanModule,
    AppRoutingModule, 
    HttpClientModule, 
    MessagingLibraryModule, 
    SortablejsModule.forRoot({ animation: 150 }),
    StoreModule.forRoot({novels: novelsReducer, navigation: navigationReducer}),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    EffectsModule.forRoot([NovelEffects, NavigationEffects]),
    BrowserAnimationsModule, 
    SharedModule
  ],
  exports: [], 
  schemas: [CUSTOM_ELEMENTS_SCHEMA], 
  bootstrap: [AppComponent]
})
export class AppModule { }
