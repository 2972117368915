<div class="row mb-2">
    <div class="col">Always interested in what you have to say. Fill out the form below and click on Submit</div>
</div>
<div class="row">
    <div class="col-sm-8">
        <form name="contactForm" #contactForm="ngForm"  novalidate>
            <div class="form-group">
                <label for="name">Name</label>
                <input type="text" name="name" [(ngModel)]="contact.name" class="form-control" required #name="ngModel" />
                <div *ngIf="name.errors && name.dirty" class="error">
                    <i class="fa fa-exclamation-triangle"></i> Name is required
                </div>
            </div>
            <div class="form-group">
                <label for="email">Email</label>
                <input type="email" name="email" [(ngModel)]="contact.email" required class="form-control" #email="ngModel"
                    email />
                <div *ngIf="email.errors && email.dirty" class="error">
                    <i class="fa fa-exclamation-triangle"></i> Invalid email address
                </div>
            </div>
            <div class="form-group">
                <label for="comment">Question or Comment</label>
                <textarea rows="6" name="comment" [(ngModel)]="contact.comment" class="form-control" required minlength="15"
                    #comment="ngModel"></textarea>
                <div *ngIf="comment.errors && comment.dirty" class="error">
                    <i class="fa fa-exclamation-triangle"></i> Comment must be at least 15 characters
                </div>
            </div>
            <button (click)="submit(contactForm)" class="btn btn-primary" [disabled]="isBusy || !contactForm.form.valid">
                Submit <i [ngClass]="{'fa fa-circle-o-notch fa-spin' : isBusy}"></i>
            </button>
        </form>
    </div>
</div>
