
    <div class="row">
        <div class="col">
            <div class="faqquestion">What is BatmanNovels.com and why does the world need it?</div>
                    <div class="faqanswer">BatmanNovels.com is here to for one reason. A lifelong Batman fan, a few years ago, I found myself enjoying the recent run of 
                    Batman novels released (Dead White, Inferno, Batman Begins novelization), so I started tracking down as many of them as I could. 
                    In attempting to do this, I noticed there wasn't a central place to easily find the novels out there, so I created this site. 
                    </div>
                <div class="faqquestion">What types of novels can I find listed here?</div>
                    <div class="faqanswer">Any DC based novel will find it's way up here regardless of Batman's precense or not. With the recent explosion of DC based tv shows and with the movie universe about to take off, I'm hoping 
            we'll be treated to an onslaught of new releases. For convenience, I've broken everything up into 4 neat little categories: 
            <br />
                        <ol>
                        <li>Batman Novels - These are original Batman stories. There might be some supporting bat characters in here, but for the most part it's all Batman</li>
                        <li>Novelizations - These are novelizations of Batman TV shows, movies, comics and games</li>
                        <li>Batman/DC Universe Novels - These are original stories or novelizations that feature DC Universe characters including Batman and/or the Bat-family. To make the list, Batman or a member of the Bat-family has to have some sort of precense in the book</li>
                        <li>Other DC Universe Novels - These original stories or novelizations might contain a small or token appearance by Batman or someone in the Bat-family or the they might not.</li>
                        </ol>
                    </div>
                <div class="faqquestion">What about Junior Novels?</div>
                <div class="faqanswer">At this time, I'm not planning on listing any Junior Novels.</div>
                <div class="faqquestion">You seem to be missing an entry or two on your list, how come it's not there?</div>
                    <div class="faqanswer">Could be because I'm not aware of it or I just haven't gotten around to posting it. Please <a href="#/Contact">send me</a> the title and author and I'll check it out.				
                    </div>
                <div class="faqquestion">I see some Superman and other DC related stuff, what about Marvel?</div>
                    <div class="faqanswer">I wouldn't hold my breath.</div>
                <div class="faqquestion">I tried to do something on your site and got an error. What do i do?</div>
                <div class="faqanswer">Please <a href="#/contact">send me</a> the error and what you were doing and I'll take a look.
            </div>
        </div>
    </div>