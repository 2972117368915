import { Injectable } from '@angular/core';
import {
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpClient
} from '@angular/common/http';

import { Observable, Subject, throwError } from 'rxjs';
import { TokenService } from '../services/token-service.service';
import { BatmanCommonService } from '../services/batman-common.service';
import { switchMap, tap, finalize } from 'rxjs/operators';
import { BatToken } from 'src/app/shared/models/batman-common-models';
import { LoaderService } from '../services/loader.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    private isRefreshingToken: boolean = false;
    private refreshedTokenSubject = new Subject();
    private $token = this.refreshedTokenSubject.asObservable();

    private callCount : number = 0;

    constructor(private tokenService: TokenService, private httpClient: HttpClient, 
        private batmanService: BatmanCommonService, private loaderService: LoaderService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        /*
        0. check to see if request is to get the refresh token and if so, skip all checks. 
        1. it authenticated, add bearer token
        2. if not authenticated, check if refresh token exits. if it does try getting new token. if successful, save token and add bearer token. 
            if not successful, just continue
        3. if no refresh token, continue

        */

        if (req.url.indexOf('/token/refresh') !== -1) {
            console.log('refresh request');
            return this.cloneRequest(req, next, null);
        }

        if(this.callCount < 0) {
            this.callCount = 0;
        }


        let token: BatToken = this.tokenService.getBatToken();
        if (this.tokenService.isAuthenticated() && !this.tokenService.isExpired(token.token)) {
            console.log('got valid token already');
            return this.cloneRequest(req, next, token.token);
        }
        else {
            if (token) {
                return this.doRefreshToken(token.refreshToken).pipe(switchMap(
                    () => {     
                        console.log('making call now');                   
                        token = this.tokenService.getBatToken();
                        return this.cloneRequest(req, next, token ? token.token : '');
                    }
                ));
            }
            else {
                return this.cloneRequest(req, next, null);
            }
        }
    }

    private cloneRequest(request: HttpRequest<any>, next: HttpHandler, token: string): Observable<HttpEvent<any>> {
        this.callCount++;
        this.loaderService.doLoadCountAction(this.callCount);
        if (token) {
            return next.handle(request.clone({ headers: request.headers.append("Authorization", "Bearer " + token) })).pipe(finalize(() => {this.loaderService.doLoadCountAction(--this.callCount)}));
        }
        else {
            return next.handle(request.clone()).pipe(finalize(() => {this.loaderService.doLoadCountAction(--this.callCount)}));;
        }
    }

    doRefreshToken(token: string) : Observable<any> {
        if (this.isRefreshingToken) {
            return new Observable(o => {
                this.$token.subscribe(
                    () => {
                        console.log('boom');
                        o.next();
                        o.complete();
                    }
                )
            });
        }
        else {
            console.log('about to refresh');
            this.isRefreshingToken = true;
            return this.batmanService.postRefreshToken(token).pipe(tap(
                res => {
                    if (res) {
                        this.tokenService.saveToken(<BatToken>res);
                    }
                    else {
                        this.tokenService.destroy();
                    }
                    this.isRefreshingToken = false;
                    this.refreshedTokenSubject.next();
                }, 
                err => {
                    console.log(err);
                }
            ));
        }
    }
}
