import { state } from '@angular/animations';
import {
    Action,
    createReducer,
    on
} from '@ngrx/store';
import * as NavigationActions from '../actions/navigation.actions';
import {Navigation_LoadNavigation, Navigation_Navigation} from '../constants'


export interface State {
    navigation: [];
}

export const initalState: State = {
    navigation: []
}

const insertItem = (array, item) => {
    let newArray = array.slice();
    newArray.push(item.navigation);
    return newArray;
}

export function navigationReducer(state: State  = initalState, action: Action){
    switch(action.type){
        case Navigation_Navigation: 
            return {
                ...state, 
                navigation: insertItem(state.navigation, action)
            }
        default:
            return state;
    }
    
}