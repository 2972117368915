<div class="row justify-content-between mb-2">
    <div class="col-md-3">
        <input type="text" class="form-control" [ngModel]="paging.searchText" placeholder="search" (ngModelChange)="setFilteredData($event)" />
    </div>
    <div class="col-md-3">
        <select class="form-control" [ngModel]="selectedNovelType" (ngModelChange)="setSelectedData($event)">
            <option [ngValue]="undefined" selected>All Novels</option>
            <option *ngFor="let ddf of novelTypes" [ngValue]="ddf">{{ddf.novelType}}</option>
        </select>
    </div>
</div>
<div class="row" *ngIf="filteredData" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="100" (scrolled)="onScroll()">
    <div class="col-md-6 col-sm-12 novels novelsImgHack pb-3" *ngFor="let data of filteredData">
        <div class="row nestedRow">
            <div class="col-3"><img src="/images/covers/{{data.images | image:'midsize'}}" alt="{{data.title}}, {{data.coverText}}" class="img-fluid" /></div>
            <div class="col-9">
               <b><a [routerLink]="['/novel', data.id]">{{data.title}}</a></b>
               <p>
                    {{showText(data.coverText)}} <a href [routerLink]="['/novel', data.id]" class="actionlink">see more of this novel</a>
                    <br />
                    Category: {{data.bNovelType.novelType}}
                    <br />
                    <app-rating [rating]="data.averageRating" [isReadOnly]="true"></app-rating>
                </p>
            </div>
        </div>
    </div>
    <div class="col" *ngIf="filteredData.length === 0 && selectedFilter === 0">
        There are no Novels to display. 
    </div>
    <div class="col" *ngIf="filteredData.length === 0 && selectedFilter !== 0">
        There are no <i>{{selectedNovelType.novelType}}</i> novels to display. 
    </div>
</div>
<div class="row pt-5" *ngIf="outsideFilteredData && outsideFilteredData.length > 0">
    <div class="col">
        <hr />
        There are additional novels that match your search text that are outside of the current novel filter of <i>{{selectedNovelType.novelType}}</i>, with a few listed below. 
        To see all the novels that match your search text across all categories, <span class="da-cursor a-span" (click)="setSelectedData('0')">clear the filter</span>.
        <div class="row pt-3">
            <div class="col-md-6 col-sm-12 novels novelsImgHack pb-3" *ngFor="let data of outsideFilteredData.slice(0,4)">
                <div class="row nestedRow">
                    <div class="col-3"><img src="/images/covers/{{data.images | image:'midsize'}}" alt="{{data.title}}, {{data.coverText}}" class="img-fluid" /></div>
                    <div class="col-9">
                       <b><a [routerLink]="['/novel', data.id]">{{data.title}}</a></b>
                       <p>
                            {{showText(data.coverText)}} <a href [routerLink]="['/novel', data.id]" class="actionlink">see more of this novel</a>
                            <br />
                            Category: {{data.bNovelType.novelType}}
                            <br />
                            <app-rating [rating]="data.averageRating" [isReadOnly]="true"></app-rating>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- 
        here show something like 
        "There are N number of novels that match your search text outside of the selected novel filter of xxxxx, a few of these are below. To see all the novels that match your search 
        text in all novel categories, click here <link to clear the novel filter>"
        and then show like 4  
     -->
</div>