<div class="row">
    <div class="col">
        <p>Holy wrong turns Batman.</p>
        <p>Looks like you got a bad link or put in a wrong url.</p>
    </div>
</div>
<div class="row">
    <div class="col">
        <div style="min-height: 120px;"></div>
    </div>
</div>

