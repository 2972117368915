import { Component, OnInit, Input } from '@angular/core';
import { Author } from '../../models/batman-common-models';

@Component({
    selector: 'authors-list',
    templateUrl: './authors-list.component.html',
    styles: []
})
export class AuthorsListComponent implements OnInit {

    @Input() authors: Author[];
    
    constructor() { }

    ngOnInit() {
    }

}
