<div class="row">
    <div class="col">
        <br />
        <p>
            No longer just Batman novels, we have expanded to include all DC Novels. It is by no means an official list but I've hunted down and read all the 
            DC novels I can find and organized them (somewhat) unde rone roof. Be warned citizen, this list does not include junior novels. 
            I think the list is pretty complete, but I occasionally stumble across a book here and there I didn't know existed. If you know of one that is not listed,
            <a routerLink="/contact">please let me know</a>.
        </p>
        <p>View the cover, read the back cover synopsys, rate and leave comments for each novel (registration required).
            To see a complete list of novels <a href [routerLink]="['../novels']">click here</a>, or you can use the Novels Quick Nav to jump to a specific novel.
        </p>
        <p>
            If you are like me and enjoy the novels, let DC know so they will keep publishing them. 
        </p>
        <br />
    </div>
</div>
<div class="row">
    <div class="col-md-6 p-2" *ngFor="let type of novelTypes$ | async">
        <div class="row">
            <div class="col-sm-3 col-xs-3"><img [src]="'/images/fpg/' + type.image" alt="{{type.description}}" class="img-responsive" /></div>
            <div class="col-sm-9 col-xs-9" style="vertical-align: top">
                <div style="font-weight: bold;font-size: 14pt;">{{type.novelType}}</div>
                <div>
                   {{type.description}}
                </div>
                <div><a href [routerLink]="['../novels', type.id]" class="actionlink">view all {{type.novelType}}</a></div>
            </div>
        </div>
    </div>
</div>