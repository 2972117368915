import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Novel, NovelImage, NovelType} from 'src/app/shared/models/batman-common-models';
import { Subscription, Subject } from 'rxjs';
import * as _ from 'lodash-es';
import { ApiPaging, ApiPagingResult } from 'src/app/shared/models/paging';
import { Store } from '@ngrx/store';
import { selectNovelTypes } from '../../store/selectors/novel.selector';
import {MessagingLibraryService} from 'angular-messaging-library';
import { BatmanService } from '../services/batman-service.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';


@Component({
    selector: 'app-novels',
    templateUrl: './novels.component.html',
    styleUrls: ['./novels.component.css']
})
export class NovelsComponent implements OnInit, OnDestroy {
    
    filteredData: Novel[] = [];
    outsideFilteredData: Novel[] = [];
    tempFilteredData: Novel[] = [];
    errorMessage: string;
    selectedNovelType: NovelType;
    unsub: Subscription;
    novelTypes: NovelType[];
    currentPage:number = 1;
    paging: ApiPaging = new ApiPaging(this.currentPage, 10, null, null, null);
    pagingResult : ApiPagingResult;
    searchChanged : Subject<string>  = new Subject<string>();

    constructor(private activeRoute: ActivatedRoute, private store: Store, 
        private batmanService : BatmanService, 
        private messageingLibraryService : MessagingLibraryService) { 

            this.searchChanged.pipe(debounceTime(1000), distinctUntilChanged()).subscribe((x) => {
                console.log(x);
                this.paging.searchText = x;
                this.resetCurrentPageAndFilteredData();
                this.doSearchAndFilter();
            });

        }


    ngOnInit() {
        
        this.store.select(selectNovelTypes).subscribe(x => {
                this.novelTypes = x;

                this.unsub = this.activeRoute.paramMap.subscribe((params: Params) => {
                    if (params.get('id')) {
                        this.selectedNovelType = _.find(this.novelTypes, a => a.id=== +params.get('id'));
                    }
                }
                );

                this.getNovels();

            }
        );

    }

    ngOnDestroy() {
        if (this.unsub) this.unsub.unsubscribe();
    }

    getNovels() {
        this.resetCurrentPageAndFilteredData();
        this.doSearchAndFilter();
    }

    setSelectedData(obj : NovelType): void {
        if (obj === this.selectedNovelType) {
            return;
        }
        this.selectedNovelType = obj;
        this.resetCurrentPageAndFilteredData();
        this.doSearchAndFilter();
    }


    setFilteredData(event: string): void {
        console.log(event);
        this.searchChanged.next(event);
    }

    getCoverImage(images: Array<NovelImage>): string {
        let image: NovelImage = images.find(x => x.imageType === 'midsize');
        if (image) return image.imageName;

        return '';
    }

    resetCurrentPageAndFilteredData(): void {
        this.paging.page = 1;
        this.tempFilteredData = [];
    }

    onScroll() : void {
        let totalPages = Math.ceil(this.pagingResult.filterSearchResults.totalRecords/this.paging.pageSize);
        if(totalPages === this.paging.page) return;

        this.paging.page += 1;
        this.doSearchAndFilter();
    }

    doSearchAndFilter() {      
        this.batmanService.getNovelsWithPaging((this.selectedNovelType && this.selectedNovelType.id) ?? 0, this.paging).subscribe(
            res => {
                this.pagingResult = res;
                 this.tempFilteredData = [...this.tempFilteredData, ...this.pagingResult.filterSearchResults.data];
                 if(this.paging.page === 1) {
                    //don't want this to page/change
                    this.outsideFilteredData = this.pagingResult.nonFilterSearchResults.data;
                 }
                 this.filteredData = this.tempFilteredData;
            }, 
            err => this.messageingLibraryService.addErrorMessage(err)
        );        
    }

    showText(coverText: string): string {
        if (coverText.length <= 150) {
            return coverText;
        }
        else {
            return coverText.substring(0, 150) + '...';
        }
    }   
  
}
