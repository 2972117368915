import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RatingComponent } from './components/rating/rating.component'
import { NgbRatingModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { PagingComponent } from './components/paging/paging.component';
import { ChaptersListComponent } from './components/chapters-list/chapters-list.component';
import { AuthorsListComponent } from './components/authors-list/authors-list.component';
import { OrderbyPipe } from './pipe/orderby.pipe';
import { ColumnSortComponent } from './components/column-sort/column-sort.component';
import { CheckmarkDirective } from './directive/checkmark.directive';
import { ConfirmFieldValidatorDirective} from './directive/confirm-field-validator.directive';
import { ImagePipe } from './pipe/image.pipe';
import { multiCrossFieldValidatorDirective } from './directive/multi-cross-field-validator.directive';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';

import {MatExpansionModule} from '@angular/material/expansion'
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';

@NgModule({
    imports: [
        CommonModule, FormsModule, NgbRatingModule, NgbPaginationModule, InfiniteScrollModule, MatExpansionModule, MatTableModule, MatPaginatorModule        
    ],
    exports: [CommonModule, FormsModule, RatingComponent, PagingComponent, ChaptersListComponent, AuthorsListComponent, 
        OrderbyPipe, ColumnSortComponent, CheckmarkDirective, ConfirmFieldValidatorDirective, ImagePipe, multiCrossFieldValidatorDirective, InfiniteScrollModule, 
        MatExpansionModule, MatTableModule, MatPaginatorModule],
    declarations: [RatingComponent, PagingComponent, ChaptersListComponent, AuthorsListComponent, OrderbyPipe, ColumnSortComponent,
        CheckmarkDirective,
        ConfirmFieldValidatorDirective,
        ImagePipe,
        multiCrossFieldValidatorDirective],
})
export class SharedModule { }
