import { Novel } from "./batman-common-models";

export class Paging {

    constructor(_pageSize: number, _currentPage: number) {
        this.currentPage = _currentPage;
        this.pageSize = _pageSize;
    }
    size: number;
    currentPage: number;
    pageSize: number;    

    startIndex = () => (this.currentPage - 1) * this.pageSize ;

    endIndex  = () => this.currentPage * this.pageSize;
}


export class ApiPaging {
    constructor(page: number, pageSize: number, orderBy: string, orderDirection: string, searchText: string) {
        this.page = page;
        this.pageSize = pageSize;
        this.orderBy = orderBy;
        this.orderDirection = orderDirection;
        this.searchText = searchText;
    }

    page: number;
    pageSize: number;
    orderBy: string;
    orderDirection: string;
    searchText: string;
}

export class ApiPagingResult {
    filterSearchResults: FilterSearchResults;
    nonFilterSearchResults: NonFilterSearchResults;
}

export class FilterSearchResults {
    totalRecords: number;
    data: Novel[];
}


export class NonFilterSearchResults {
    totalRecords: number;
    data: Novel[];
}