import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-rating',
    templateUrl: './rating.component.html',
    styles: [`
        ngb-rating {
            outline:none;
        }
        .star {
          position: relative;
          display: inline-block;
          font-size: 1.75rem;
          color: #d3d3d3;
        }
        .full {
          color: black;
        }
        .half {
          position: absolute;
          display: inline-block;
          overflow: hidden;
          color: black;
        }`
    ]
})
export class RatingComponent {

    @Input() rating: number;
    @Input() isReadOnly: boolean;
    @Output() onRatingChanged: EventEmitter<number> = new EventEmitter<number>();

    constructor() {

    }

    // ngOnChanges(changes: SimpleChanges) {
    //     const log: string[] = [];
    //     for (const propName in changes) {
    //         const changedProp = changes[propName];
    //         const to = JSON.stringify(changedProp.currentValue);
    //         if (changedProp.isFirstChange()) {
    //             console.log(`Initial value of ${propName} set to ${to}`);
    //         } else {
    //             const from = JSON.stringify(changedProp.previousValue);
    //             console.log(`${propName} changed from ${from} to ${to}`);
    //         }
    //     }
    // }


    ratingChanged($event): void {
        console.log('rating changed ' + $event);
        if (this.isReadOnly) return;
        this.rating = parseInt($event);
        if (this.rating === 0) return;
        this.onRatingChanged.emit(this.rating);
    }

}
