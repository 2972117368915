
<div *ngIf="novel else invalidNovel">
    <div class="row">
        <div class="col-md-8">
            <div class="titleheader">{{novel.title}}</div>
            <div class="covertext" [innerHtml]="formattedCoverText"></div>
            <div class="spacer"></div>
            <div style="text-align: left; padding-left: 5px; padding-top: 10px">
                <div *ngIf="novel.authors?.length > 0">
                    <div [ngSwitch]="novel.bNovelType?.novelType">
                        <span *ngSwitchCase="'novelization'">Novelization By: </span>
                        <span *ngSwitchDefault>Author(s): </span>
                        <authors-list [authors]="novel.authors"></authors-list>
                    </div>
                </div>
                <div *ngIf="novel?.chapters?.length > 0">
                    <p>Chapters:</p>
                    <chapters-list [chapters]="novel.chapters"></chapters-list>
                </div>
            </div>
            <div style="text-align: left; padding-left: 5px; padding-top: 10px">Published: {{novel.publishedBy?.name}}, {{novel.published}}</div>
        </div>
        <div class="col-md-4 text-center">
            <app-alt-image [novel]="novel" [images]="novel.images"></app-alt-image>            
            <div id="NovelUserRating" *ngIf="avgRating">
                <b>Rating: </b><app-rating [rating]="avgRating.rating" [isReadOnly]="'true'"></app-rating>
                <div *ngIf="avgRating.numratings > 0">
                    <a class="da-cursor" (click)="showAllRatings()">
                        Rated by {{avgRating.numratings}}
                        <span *ngIf="avgRating.numratings == 1">person</span>
                        <span *ngIf="avgRating.numratings > 1">people</span>
                    </a>
                </div>
                <div *ngIf="avgRating.numratings == 0">Be the first to rate this novel</div>
                <div *ngIf="isAuth" class="text-center d-flex justify-content-center align-items-center">
                    <div class="pr-2" *ngIf="userRating?.novelRating > 0" (click)="removeRating()"><i class="fa fa-trash fa-lg text-grey da-cursor"></i></div>
                    <div *ngIf="userRating"><app-rating [rating]="userRating.novelRating" [isReadOnly]="false" (onRatingChanged)="onRatingChange($event)"></app-rating></div>
                </div>
                <div *ngIf="!isAuth"><a [routerLink]="['/account/login']" [queryParams]="{redirect: 'novel/' + novelId}">Login</a> to rate this novel.</div>
            </div>
        </div>
    </div>
    <hr />
    <related-novels [novelId]="novelId" class="hidden-xs"></related-novels>
    <hr />
    <novel-comments [novelId]="novelId"></novel-comments>
</div>
<ng-template #invalidNovel>
    <div class="row">
        <div class="col my-5">
            There was an issue retrieving that novel. Please retry or select a different novel from the Quick Nav above or go back to the <a href routerLink="/novels">Novels</a> page.
        </div>
    </div>
</ng-template> 