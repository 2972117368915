import { Component } from '@angular/core';
import { BatmanService } from '../services/batman-service.service';
import { Contact } from '../models/batman-models';
import { MessagingLibraryService } from 'angular-messaging-library';
import { BatAlert } from 'src/app/shared/models/messaging';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styles: []
})
export class ContactComponent {

    isBusy: boolean;
    contact: Contact = new Contact();

    constructor(private batmanService: BatmanService, private messageService: MessagingLibraryService) { }

    submit(contactForm : NgForm): void {
        this.isBusy = true;
        this.batmanService.sendContact(this.contact).subscribe(
            () => {
                this.isBusy = false;
                contactForm.resetForm();
                this.messageService.addSuccessMessage('Thanks for the message chum. We will get back to you as soon as possible');
                this.contact = new Contact();
            },
            () => {
                this.isBusy = false;
                //this.errMessage = 'An error has occurred submitting your question/comment. Please try again later.';
            }
        );
    }

}
