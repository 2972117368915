import {
    Action,
    createReducer,
    on
} from '@ngrx/store';
import { NovelType, Novel, QuickNav } from 'src/app/shared/models/batman-common-models';
import * as CommonNovelActions from '../actions/novel.actions';


export interface State {
    novelTypes: NovelType[];
    novels: Novel[];
    quickNav: QuickNav[];
    lastError: any
}

export const initalState: State = {
    novelTypes: [], 
    novels: [],
    quickNav: [],
    lastError: null
};

const _novelsReducer = createReducer(
    initalState,
    on(CommonNovelActions.novelTypesLoaded, (state, {novelTypes}) => state), 
    on(CommonNovelActions.loadNovelTypes, (state) => state), 
    on(CommonNovelActions.setNovelTypes, (state, { novelTypes }) =>  ({ ...state, novelTypes: novelTypes })),     
    on(CommonNovelActions.setQuickNav, (state, {quickNav}) => ({...state, quickNav: quickNav})),
    on(CommonNovelActions.quickNavsLoaded, (state, {quickNav}) => state), 
    on(CommonNovelActions.loadQuckNav, (state) => state), 
    on(CommonNovelActions.handleError, (state, lastError) => ({...state, lastError: lastError}))
);

export function novelsReducer(state: State | undefined, action: Action) {
    return _novelsReducer(state, action);
}
