import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Chapter } from '../../models/batman-common-models';

@Component({
    selector: 'chapters-list',
    templateUrl: './chapters-list.component.html',
    styles: []
})
export class ChaptersListComponent  {

    @Input() chapters: Chapter[];
    @Input() editable: boolean = false;
    @Output() onEdit: EventEmitter<any> = new EventEmitter();
    @Output() onDelete: EventEmitter<any> = new EventEmitter();


    constructor() { }

    edit(chapter: Chapter): void {
        this.onEdit.emit(chapter);
    }

    delete(id: number): void {
        this.onDelete.emit(id);
    }


}
