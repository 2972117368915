
export class User {

    constructor() {}

    userName: string;
    emailAddress: string;
    Authentication: any;
}

export class UserSummary extends User {
    userId: string;
    created?: Date;
    lastLogin?: Date;
    numberOfComments: number;
    numberOfRatings: number;
    isConfirmed: boolean;
    isMembershipUser: boolean;
}

