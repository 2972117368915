<div class="row mt-3">
    <div class="col">        
        <img src="/images/covers/{{selectedImage?.imageName}}" alt="{{novel.title}}, {{cleanText(novel.coverText)}}" />
    </div>
</div>
<div class="row mt-3" *ngIf="filteredImages.length > 1">
    <div class="col center-text">
        <span *ngFor="let image of filteredImages">            
            <img src="/images/covers/{{image.imageName}}" class="img-thumbnail bat-thumb selected-image bat-opacity"  *ngIf="image.imageName === selectedImage.imageName" />
            <img src="/images/covers/{{image.imageName}}" class="img-thumbnail bat-thumb da-cursor" (click)="selectedImage = image" *ngIf="image.imageName !== selectedImage.imageName" />
        </span>
    </div>
</div>