import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BatmanService } from '../services/batman-service.service';
import { TokenService } from 'src/app/core/services/token-service.service';
import { Comment, Novel } from '../../shared/models/batman-common-models';
import * as moment from 'moment';
import { Paging } from 'src/app/shared/models/paging';

@Component({
    selector: 'novel-comments',
    templateUrl: './comments.component.html',
    styles: []
})
export class CommentsComponent implements OnChanges {

    @Input() novelId: number;
    comments: Comment[];
    pagedComments: Comment[];
    isBusy: boolean = false;
    comment: string;
    isAuthenticated: boolean;
    paging: Paging = new Paging(6, 1);

    constructor(private batmanService: BatmanService,
        private tokenService: TokenService) { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['novelId']) {
            this.isAuthenticated = this.tokenService.isAuthenticated();
            this.novelId = parseInt(changes['novelId'].currentValue);
            this.comment = '';
            this.getComments();
        }
    }

    getComments() {
        if (this.novelId) {
            this.batmanService.getNovelComments(this.novelId).subscribe(
                stuff => {
                    this.comments = stuff;
                    this.paging.size = this.comments.length;
                    this.setPageFilter();
                },
                error => console.log(error)
            );
        }
    }

    pageChanged($event:number): void {
        this.paging.currentPage = $event;
        this.setPageFilter();
    }

    setPageFilter() : void {
        this.pagedComments = this.comments.slice(this.paging.startIndex(), this.paging.endIndex());
    }

    formatText(text): string {
        if (text) {
            text = text.replace(/\n/g, "<br />");
            return text;
        }
    };

    submitComment(): void {
        if (this.comment) {
            let _comment: Comment = new Comment();
            let _novel : Novel = new Novel();
            _novel.id = this.novelId;
            _comment.createComment(this.comment,  _novel);
            this.isBusy = true;
            this.batmanService.postNovelComment(_comment).subscribe(
                () => {
                    this.comment = undefined;
                    this.getComments();
                    this.isBusy = false;
                },
                error => {
                    console.log(error);
                    this.isBusy = false;
                }
            );
        }
    }

    formatDate(date: Date): string {
        let _date = moment.utc(date).toDate();
        return moment(_date).local().fromNow();
    }

}
