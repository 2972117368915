export const Navigation_Navigation : string = '[Navigation] Navigation';
export const Navigation_NavigationLoaded : string = '[Navigation] Navigation Loaded';
export const Navigation_LoadNavigation: string = '[Navigation] Load Navigation';

export const CommonNovel_NovelTypes : string = '[Common Novel] NovelTypes';
export const CommonNovel_NovelTypesLoaded : string = '[Common Novel] NovelTypes Are Loaded';
export const CommonNovel_LoadNovelTypes : string = '[Common Novel] Load NovelTypes';

export const CommonNovel_QuickNav : string = '[Common Novel] Quick Nav';
export const CommonNovel_QuickNavsLoaded : string = '[Common Novel] Quick Nav is Loaded';
export const CommonNovel_LoadQuickNav : string = '[Common Novel] Load Quick Nav';