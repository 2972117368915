import {createSelector} from '@ngrx/store';
import { AppState } from '../state';


export const selectNavigation = createSelector(
    (state: AppState) => state.navigation, 
    (navigation) => navigation
);

export const selectNavigationProps = createSelector(
    (state: AppState) => state.navigation, 
    (navigation, props) => navigation.navigation.filter(x =>  {
        return x.module === props.module 
    })
);