import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BatmanComponent } from './batman/batman.component';
import { NotFoundComponent } from './not-found.component';

const routes: Routes = [
    { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
    { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
    { path: 'comicdb', loadChildren: () => import('./comicdb/comicdb.module').then(m => m.ComicdbModule) },
    { path: 'cardsdb', loadChildren: () => import('./cardsdb/cardsdb.module').then(m => m.CardsdbModule) },
    { path: '', loadChildren: () => import('./batman/batman.module').then(m => m.BatmanModule) },
     { path: '', redirectTo: "/", pathMatch: 'full' },    
     { path: '**', component: NotFoundComponent,  data: { Title: 'Page Not Found'} }
    // { path: '**', component: BatmanComponent }
  
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
