<div class="row">
    <div class="col">
        <div><strong>Comments:</strong></div>
        <div class="mb-1">
            <div *ngIf="isAuthenticated">
                <p>Add your comments concerning this novel:</p>
                <form #commentForm="ngForm" name="commentForm">
                    <div class="form-group">
                        <textarea rows="4" [(ngModel)]="comment" name="comment" ngControl="comment" class="form-control"
                            ng-minlength="5" required></textarea>
                    </div>
                    <button (click)="submitComment()" [disabled]="isBusy || !commentForm.form.valid" class="btn btn-primary">Add
                        Comment <i [ngClass]="{'fa fa-circle-o-notch fa-spin' : isBusy}"></i></button>
                </form>
            </div>
            <div *ngIf="!isAuthenticated"><i><a [routerLink]="['/account/login']" [queryParams]="{redirect: 'novel/' + novelId}">Login</a> to add a comment</i></div>
        </div>
        <div>
            <div *ngFor="let comment of pagedComments">
                <div class="greyitalic">posted: {{formatDate(comment.createdDate)}} by {{comment.userName}}</div>
                <div class="commentwrapper" [innerHtml]="formatText(comment.novelComment)"></div>
            </div>
            <div *ngIf="pagedComments && pagedComments.length === 0">
                There are no comments for this novel.
            </div>
        </div>
        <div *ngIf="pagedComments && pagedComments.length > 0">
            <app-paging [paging]="paging" (onPageChanged)="pageChanged($event)"></app-paging>
        </div>
    </div>
</div>