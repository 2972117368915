import { Injectable } from '@angular/core';
import { BatToken } from 'src/app/shared/models/batman-common-models';
import {JwtHelperService} from '@auth0/angular-jwt';
import { User } from 'src/app/shared/models/user-models';
import { AuthenticatedService } from './authenticated.service';
import * as _ from 'lodash-es';


const helper = new JwtHelperService();

@Injectable({
    providedIn: 'root'
})
export class TokenService {

    private batToken: string = "BatToken";

    constructor(private authenticatedService: AuthenticatedService) { }

    saveToken(model: BatToken) : void {
        localStorage.setItem(this.batToken, JSON.stringify(model));
        this.authenticatedService.onAuthentication();
    }

    destroy() : void {
        localStorage.removeItem(this.batToken);
        this.authenticatedService.onAuthentication();
    }

    isAuthenticated() : boolean {
        let token : BatToken = this.getTokenHelper();
        return this.isAuthenticatedHelper(token);
    }

    isExpired(token: string) : boolean {
        if(token) {
            return helper.isTokenExpired(token);
        }

        return true;
    }

    getUser(): User {
        let token: BatToken = this.getTokenHelper();
        if(this.isAuthenticatedHelper(token)) {
            let x : User = helper.decodeToken(token.token);
            return x;
        }

        return new User();
    }

    hasClaim(claim: string): boolean {
        let user : User = this.getUser();
        if (!user) return false;
        if(user.Authentication instanceof Array)
            return _.indexOf(user.Authentication, claim) !== -1;        
        else
            return user.Authentication === claim;
    }

    getBatToken(): BatToken {
        return this.getTokenHelper();
    }

    private isAuthenticatedHelper(token: BatToken) : boolean {

        if(!token) return false;

        //if(token && helper.isTokenExpired(token.token, 90)) return false;

        return true;
    }

    private getTokenHelper() : BatToken {
        let tokenString: string = localStorage.getItem(this.batToken);
        if(tokenString)
        {
            return JSON.parse(tokenString);
        }

        return null;        
    }


}
