<div class="row mb-1">
    <div class="col">
        <span class="related-novel-header">Novels that are similar to this one</span>
    </div>
</div>
<div class="row" *ngIf="novels">
    <div class="col-6 col-md-2" *ngFor="let novel of novels; let isFirst = first" [ngClass]="{'offset-md-1': isFirst }">
         <div class="row">
            <div class="col text-center">
                <a [routerLink]="['/novel', novel.id]"><img src="/images/covers/{{novel.bookImage_100}}" alt="{{novel.title}}" class="img-responsive" style="border: none" /></a>
            </div>
         </div>
         <div class="row">
            <div class="col text-center">
                <a [routerLink]="['/novel', novel.id]">{{novel.title}}</a>
            </div>
        </div>
    </div>
</div>