
export class Contact {

    constructor() {        
        this.name = '';
        this.email = '';
        this.comment = '';
    }
    
    name: string;
    email: string;
    comment: string;
}

