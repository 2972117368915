import { Component, OnInit } from '@angular/core';
import { Novel, RatingSummary, Rating, NovelImage } from 'src/app/shared/models/batman-common-models';
import { BatmanService } from '../services/batman-service.service';
import { TokenService } from 'src/app/core/services/token-service.service';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { RatedComponent } from '../rated/rated.component';
import * as _ from 'lodash-es';
import { Store } from '@ngrx/store';

@Component({
    selector: 'app-novel',
    templateUrl: './novel.component.html',
    styles: []
})
export class NovelComponent implements OnInit {

    novel: Novel;
    novelId: number;
    avgRating: RatingSummary;
    isAuth: boolean = false;
    userRating: Rating;
    selectedImage: NovelImage;
    formattedCoverText: string;
    invalidNovel: boolean;

    private sub: any;

    constructor(private batmanService: BatmanService,
        private tokenService: TokenService,
        private activatedRoute: ActivatedRoute,
        private modalService: NgbModal, 
        private store: Store) { }


    ngOnInit(): void {
        this.isAuth = this.tokenService.isAuthenticated();
        this.sub = this.activatedRoute.paramMap.pipe(switchMap(params => of(params.get('id')))).subscribe((res) =>   
            {   
                this.novelId = +res;      
                this.getNovel(this.novelId);
                this.getRatings(this.novelId);
                this.getUserRating(this.novelId);
            }
        )
    }

    ngOnDestroy(): void {
        console.log('unsubscribing');
        this.sub.unsubscribe();
    }

    errFunction(error: any): void {
        console.log(error);
    }

    cleanText(text): string {
        if (text) {
            return text.replace(/\"/g, "");
        }
    };

    formatText(text): string {
        if (text) {
            text = text.replace(/\n/g, "<br />");
            return text;
        }
    };


    getNovel(novelId: number): void {      
        this.invalidNovel = false;
        this.batmanService.getNovelById(novelId).subscribe(
            res => {
                this.formattedCoverText = '';
                if(res) {
                    this.novel = res;
                    this.formattedCoverText = this.formatText(this.novel.coverText);
                    this.setSelectedImage('primary');
                }
                else {
                    this.invalidNovel = true;
                }
            },
            error => this.errFunction(error)
        );
    }

    setSelectedImage(imageType : string)  {
        this.selectedImage = _.find(this.novel.images, x => x.imageType === imageType);
    }

    getRatings(novelId: number): void {
        this.batmanService.getAverageRating(novelId).subscribe(
            rating => {
                this.avgRating = rating;
            },
            error => this.errFunction(error)
        );
    }

    getUserRating(novelId: number): void {        
        if (!this.isAuth)  return;

        this.batmanService.getUserRating(novelId).subscribe(
            res => {
                this.userRating = res;
            },
            err => this.errFunction(err)
        );
    }

    onRatingChange($event: number): void {
        console.log($event + " was passed to the parent");
        this.updateRating($event);
       
    }

    showAllRatings() : void {        
        this.batmanService.getRatings(this.novelId).subscribe(
            res => {
                const modalRef = this.modalService.open(RatedComponent);
                modalRef.componentInstance.novelTitle = this.novel.title;
                modalRef.componentInstance.ratings = res;
            },
            err => this.errFunction(err)
        );
    }

    removeRating() : void {
        this.updateRating(0);
    }

    createRating(rating: number) : Rating {
        return  <Rating>{
            novelRating: rating, parentNovel: { id: this.novelId }
        }
    }

    updateRating(rating: number) : void {
        this.batmanService.postNovelRating(this.createRating(rating)).subscribe(
            () => {
                this.getRatings(this.novelId);
                this.getUserRating(this.novelId);                
            },
            err => this.errFunction(err)
        );
    }
}
