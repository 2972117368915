<div class="container">
    <div class="row">
        <div class="col">
            <nav class="navbar navbar-dark bat-header navbar-expand-lg flex-column">
                <div class="w-100 d-flex">
                    <button type="button" class="navbar-toggler collapsed" data-toggle="collapse" data-target="#navCollapse"
                        aria-controls="navCollapse" aria-expanded="false" aria-label="Toggle navigation" #batmanNav>
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <a class="navbar-brand" routerLink="/">
                        <img src="assets/images/header_2.png" alt="" class="img-fluid" />
                    </a>
                </div>
                <div class="collapse navbar-collapse justify-content-between w-100" id="navCollapse">
                    <ul class="navbar-nav">
                        <li class="nav-item"><a (click)="collapseNav()" routerLink="/">Home</a></li>
                        <li class="nav-item"><a (click)="collapseNav()" routerLink="/novels">Novels</a></li>
                        <li class="nav-item"><a (click)="collapseNav()" routerLink="/faq" class="topnav">FAQ</a></li>
                        <li class="nav-item"><a (click)="collapseNav()" routerLink="/contact" class="topnav">Contact</a></li>
                        <li class="nav-item" *ngIf="hasClaim('Admin')"><a (click)="collapseNav()" routerLink="/admin/" class="topnav">Admin</a></li>
                        <li class="nav-item" *ngIf="hasClaim('ComicDb') || hasClaim('ComicViewer')"><a (click)="collapseNav()" routerLink="/comicdb/" class="topnav">ComicDb</a></li>
                        <li class="nav-item" *ngIf="hasClaim('CardsDb') || hasClaim('CardsViewer')"><a (click)="collapseNav()" routerLink="/cardsdb/" class="topnav">Cards</a></li>
                    </ul>
                    <ul class="navbar-nav  ml-auto">
                        <li class="nav-item" *ngIf="!user"><a (click)="collapseNav()" routerLink="/account/register" class="topnav">Register</a></li>
                        <li class="nav-item" *ngIf="!user"><a (click)="collapseNav()" routerLink="/account/login" class="topnav">Login</a></li>
                        <li class="nav-item" *ngIf="user"><a (click)="collapseNav()" routerLink="/account/profile"><span>{{user.userName}}</span></a></li>
                        <li class="nav-item" *ngIf="user"><a (click)="collapseNav()" routerLink="" (click)="logOff()" class="topnav">Log off</a></li>
                    </ul>
                </div>
            </nav> <!-- end nav -->
        </div>
    </div>
    <div>
        <ul class="nav sub-nav" *ngIf="currentsubnavs.length > 0">
            <li class="nav-item"  *ngFor="let item of currentsubnavs">
                <a *ngIf="!item.external; else externalBlock" class="nav-link" [routerLinkActive]="['sub-nav-active']" [routerLink]="[item.route]">{{item.display}}</a>
                <ng-template #externalBlock><a class="nav-link" [href]="item.route" target="_blank">{{item.display}}</a></ng-template>
            </li>
        </ul>
    </div>
    <app-loader></app-loader>
    <div class="row mb-3">        
        <div *ngIf="pageTitle !== ''" class="col-md-6 col-sm-12 pagetitle pt-3">{{pageTitle}}</div>
        <div [ngClass]="{ 'col-sm-12': pageTitle === '','col-md-6' : pageTitle !== '' }" class="pt-1" *ngIf="showQuicknav">
            <quick-nav class="float-right"></quick-nav>
        </div>
    </div>
    <lib-messaging-library></lib-messaging-library>
    <router-outlet></router-outlet>
</div>
<div class="container mt-4">
    <div class="row">
        <div class="col-sm-12">
            <hr />
            BatmanNovels.com - &copy; {{year}}. Batman and all related characters are property of Warner Bros/DC
            Comics.
            <br />
            Having trouble or getting an error? <a href="mailto:admin@batmannovels.com">send me an Email</a>
        </div>
    </div>
</div>