import { Injectable } from '@angular/core';
import {Store, select} from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import {selectNavigation, selectNavigationProps} from '../selectors/navigation.selector';
import { BatmanCommonService } from 'src/app/core/services/batman-common.service';
import {Navigation_Navigation, Navigation_LoadNavigation, Navigation_NavigationLoaded} from '../constants';


@Injectable()
export class NavigationEffects {

    constructor(private batmanCommonService: BatmanCommonService, private actions$: Actions, private store: Store){}

    loadNavigation$ = createEffect(() => this.actions$.pipe(
        ofType(Navigation_LoadNavigation),
        withLatestFrom(this.store.select(selectNavigation)), 
        // tap(([action, nav]) => {
        //     console.log(action, nav);
        // }),
        switchMap(([action, nav]) => {
            //console.log(action, action['module']);
            let mod = nav['navigation'].filter(x =>  { 
                return x['module'] === action['module']; 
            })
            if(mod && mod.length > 0) {
                //console.log('found ' + mod);
                return of({type: Navigation_NavigationLoaded, navigation: mod})
            }
            return this.batmanCommonService.getNavigation(action['module']).pipe(
                map(res => ({type: Navigation_Navigation, navigation: {module: action['module'], payload: res}})), 
                catchError(err => of({ type: '[Common Novel] Novel Error', payload: err })))                      
        })
    ));

}