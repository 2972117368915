import { NgModule } from '@angular/core';
import { SharedModule} from '../shared/shared.module';
import { BatmanRoutingModule } from './batman-routing.module';
import { HomeComponent } from './home/home.component';
import { BatmanComponent } from './batman.component';
import { NovelsComponent } from './novels/novels.component';
//import {QuickNavComponent} from './quicknav/quick-nav.component';
import { NovelComponent } from './novel/novel.component';
import { CommentsComponent } from './comments/comments.component';
import { ContactComponent } from './contact/contact.component';
import { RatedComponent } from './rated/rated.component';
import { RelatedNovelsComponent } from './related-novels/related-novels.component';
import { FaqComponent } from './faq/faq.component';
import { AltImageComponent } from './alt-image/alt-image.component';

@NgModule({
  imports: [
    SharedModule, 
    BatmanRoutingModule
  ],
  exports: [], 
  entryComponents: [RatedComponent], 
  declarations: [HomeComponent, BatmanComponent, NovelsComponent, NovelComponent, CommentsComponent, ContactComponent, 
        RatedComponent, RelatedNovelsComponent, FaqComponent, AltImageComponent]
})
export class BatmanModule { }
