import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Router } from '@angular/router';
import {HttpExtendService} from './http-extend.service';
import { Observable } from 'rxjs';
import { Novel, RatingSummary, Rating, RelatedNovel, NovelType, BatToken, ModuleNavigation, QuickNav } from '../../shared/models/batman-common-models';
import { MessagingLibraryService } from 'angular-messaging-library';
import { CharacterType } from 'src/app/admin/shared/admin-models';

@Injectable({
    providedIn: 'root'
})
export class BatmanCommonService extends HttpExtendService {

    
    constructor(httpClient: HttpClient, router: Router, messagingService: MessagingLibraryService) { 
        super(httpClient, router, messagingService);
    }

    getNovels(): Observable<Novel[]> {
        return this.get('/batman/novels');
    }

    getNovelById(novelId: number): Observable<Novel> {
        return this.get('/batman/novel/' + novelId);
    }

    getAverageRating(novelId: number) : Observable<RatingSummary> {
        return this.get('/batman/averagerating/' + novelId);
    }

    getNovelComments(novelId: number): Observable<Comment[]> {
        return this.get('/batman/comments/' + novelId);
    }


    getUserRating(novelId: number): Observable<Rating> {
        return this.get('/batman/rating/' + novelId);
    }

    getRatings(novelId: number): Observable<Rating[]> {
        return this.get('/batman/ratings/' + novelId);
    }

    postNovelRating(rating: Rating): Observable<any> {
        return this.post('/batman/rating/', rating);
    }

    getNovelTypes() : Observable<NovelType[]> {
        return this.get<NovelType[]>('/batman/novel/types');
    }

    getRelatedNovels(novelId: number) : Observable<Array<RelatedNovel>> {
        return this.get('/batman/novel/' + novelId + '/related');
    }

    postRefreshToken(token: string) : Observable<BatToken> {
        return this.post('/authenticate/token/refresh?token=' + encodeURIComponent(token), null);
    }

    getNavigation(module: string) : Observable<Array<ModuleNavigation>> {
        return this.get(`/batman/navigation/${module}`);
    }
    
    getQuickNav() : Observable<Array<QuickNav>> {
        return this.get('/batman/navigation/quick');
    }    

    getCharacterTypes() : Observable<Array<CharacterType>> {
        return this.get('/batman/types/character');
    }

}
