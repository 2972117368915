import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticatedService {

    private authenticationAction = new Subject();

    authenticated$ = this.authenticationAction.asObservable();

    constructor() { }

    onAuthentication() : void {
        this.authenticationAction.next();
    }
}
