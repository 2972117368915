import { Component, OnInit } from '@angular/core';
import {Store} from '@ngrx/store';
import { NovelType } from 'src/app/shared/models/batman-common-models';
import {selectNovelTypes} from '../../store/selectors/novel.selector';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

    novelTypes$  = this.store.select(selectNovelTypes);

    constructor(private store: Store) { }

    ngOnInit() {
        //this.getNovelTypes();
    }

    // getNovelTypes(): void {
    //     this.store.dispatch({type: '[Common Novel] Load NovelTypes'});  
    // } 

}
